import { request } from "./request";

export default function modifyBlog(payload) {
    const url = process.env.NODE_ENV === 'production' ? '/api/blog/modify/' : '/blog/modify/';  
    return request({
        url: url + payload.objectId + '/',
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'X-LC-Id': 'r57JSq9yQU4WHkBh3WlJktM0-gzGzoHsz',
            'X-LC-Key': '6B1HmkLavgUf3SKXAGziVUhK'
        },
        data: JSON.stringify(payload.blogItem)
    })
}