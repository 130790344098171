import { request } from "./request";
export default function createBlog(newBlog) {
  const url = process.env.NODE_ENV === 'production' ? '/api/blog/create/' : '/blog/create/';  
  return request({
    url:url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-LC-Id': 'r57JSq9yQU4WHkBh3WlJktM0-gzGzoHsz',
      'X-LC-Key': '6B1HmkLavgUf3SKXAGziVUhK'
    },
    data: JSON.stringify(newBlog)
  })
}
